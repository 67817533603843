import React, { Suspense, lazy } from "react";
import Homepage from "./components/game-homepage"

import { Routes, Route } from "react-router-dom";
import "./App.css";

// import ResultDetails from "./components/resultdetails.js"



// import MultiplayerEnterName from "./components/wait";
// import Result from "./components/result";
// import CreateRoom from "./components/creators-room";
// import Otherplayer from "./components/otherplayers";
const MultiplayerEnterName = lazy(() => import("./components/wait"))
const Result = lazy(() => import("./components/result"))
const CreateRoom = lazy(() => import("./components/creators-room"))
const Otherplayer = lazy(() => import("./components/otherplayers"))
const ResultDetails = lazy(() => import("./components/resultdetails"))
const HowToPlay = lazy(() => import("./components/howtoplay.js"))




const ShowAplhabet1 = lazy(() => import("./components/rounds/showalphabet_1"))
const MainGamePage1 = lazy(() => import("./components/rounds/maingame_1"))
const ShowAplhabet2 = lazy(() => import("./components/rounds/showalphabet_2"))
const MainGamePage2 = lazy(() => import("./components/rounds/maingame_2"))
const ShowAplhabet3 = lazy(() => import("./components/rounds/showalphabet_3"))
const MainGamePage3 = lazy(() => import("./components/rounds/maingame_3"))
const ShowAplhabet4 = lazy(() => import("./components/rounds/showalphabet_4"))
const MainGamePage4 = lazy(() => import("./components/rounds/maingame_4"))
const ShowAplhabet5 = lazy(() => import("./components/rounds/showalphabet_5"))
const MainGamePage5 = lazy(() => import("./components/rounds/maingame_5"))
const ShowAplhabet6 = lazy(() => import("./components/rounds/showalphabet_6"))
const MainGamePage6 = lazy(() => import("./components/rounds/maingame_6"))
const ShowAplhabet7 = lazy(() => import("./components/rounds/showalphabet_7"))
const MainGamePage7 = lazy(() => import("./components/rounds/maingame_7"))
const ShowAplhabet8 = lazy(() => import("./components/rounds/showalphabet_8"))
const MainGamePage8 = lazy(() => import("./components/rounds/maingame_8"))
const ShowAplhabet9 = lazy(() => import("./components/rounds/showalphabet_9"))
const MainGamePage9 = lazy(() => import("./components/rounds/maingame_9"))
const ShowAplhabet10 = lazy(() => import("./components/rounds/showalphabet_10"))
const MainGamePage10 = lazy(() => import("./components/rounds/maingame_10"))




function App() {
  return (
    <div className="App ">

      <Routes>
        <Route path="/" element={

       <Suspense>
         <Homepage />
       </Suspense>
        } />

        <Route path="/howtoplay" element={
        <Suspense> <HowToPlay /> </Suspense>
        } />

        <Route path="/createroom" element={
        

        <Suspense>
         <CreateRoom />
       </Suspense>
        } />




        <Route path="/wait/:link/:alreadyusername" element={

        <Suspense>
         <MultiplayerEnterName />
       </Suspense>
        } />

        <Route path="/result/:link" element={

        <Suspense>
         <Result />
       </Suspense>
        } />

        <Route path="/othersjoin" element={

        <Suspense>
         <Otherplayer />
       </Suspense>
        } />


       <Route path="/result-details/:link" element={
         <Suspense>
          <ResultDetails />
         </Suspense>
         } />








        <Route path="/alphabet-display1/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet1 />
       </Suspense>
        } />

        <Route path="/game1/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage1 />
       </Suspense>
        } />

        <Route path="/alphabet-display2/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet2 />
       </Suspense>
        } />

        <Route path="/game2/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage2 />
       </Suspense>
        } />

        <Route path="/alphabet-display3/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet3 />
       </Suspense>
        } />

        <Route path="/game3/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage3 />
       </Suspense>
        } />

        <Route path="/alphabet-display4/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet4 />
       </Suspense>
        } />

        <Route path="/game4/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage4 />
       </Suspense>
        } />

        <Route path="/alphabet-display5/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet5 />
       </Suspense>
        } />

        <Route path="/game5/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage5 />
       </Suspense>
        } />

        <Route path="/alphabet-display6/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet6 />
       </Suspense>
        } />

        <Route path="/game6/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage6 />
       </Suspense>

        } />

        <Route path="/alphabet-display7/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet7 />
       </Suspense>
        } />

        <Route path="/game7/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage7 />
       </Suspense>
        } />

        <Route path="/alphabet-display8/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet8 />
       </Suspense>
        } />

        <Route path="/game8/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage8 />
       </Suspense>
        } />

        <Route path="/alphabet-display9/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet9 />
       </Suspense>

        } />
        <Route path="/game9/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage9 />
       </Suspense>
        } />

        <Route path="/game10/:link/:name/:roundNumber/:alphabet" element={

        <Suspense>
         <MainGamePage10 />
       </Suspense>

        } />
        <Route path="/alphabet-display10/:link/:name/:roundNumber" element={

        <Suspense>
         <ShowAplhabet10 />
       </Suspense>
        } />


        {/* Add more routes as needed */}
      </Routes>


    </div>
  );
}

export default App;
