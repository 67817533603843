import React from 'react';
import { Link } from "react-router-dom";
import logo from "../static/Logo.svg"

function App() {
  return (
    <div className=' h-[75vh]'>
      <div className="table-container">
        <div className='flex justify-center mt-32'>
          <img src={logo} alt='Logo' className='w-72 h-20'/>
        </div>

        <div className='mt-12 text-center'>
          <button className='bg-[#EF900D] rounded-[15px] border border-black text-white text-xl w-[280px] py-2'>
            <Link to="/createroom">CREATE A NEW GAME</Link>
          </button><br />
          <button className='bg-[#319F28] rounded-[15px] border border-black text-white text-xl w-[280px] py-2 mt-8'>
            <Link to="/othersjoin">JOIN EXISTING GAME</Link>
          </button><br />
          <div className='mt-20'>
            <Link to="howtoplay">
              <button className='text-[20px] underline w-60 text-[#F4EF77] font-[1000]'>HOW TO PLAY?</button><br />
            </Link>
            <p className='font-[400] text-[10px] leading-[-0.17px] text-white'>THIS IS AN ALL ENGLISH GAME</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
